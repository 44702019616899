// Implement GetUserAgent action

import { getFullEchoApi } from "../apis/GetFullEchoApi"

export const getOsBrowser = async () => {

  return await getFullEchoApi()
    .then((response) => {
      const device_statement = (!response.hasOwnProperty("os") ? "Device information was not found." :
        "\n\nYou are running a **" + response.os + "** device " +
        ((!response.hasOwnProperty("os_version") || response.os_version === "") ? "" :
          "(v" + response.os_version + ")") + ".")
      const browser_statement = (!response.hasOwnProperty("browser_name") ? "browser information was not found." :
        "\n\nYou are using **" + response.browser_name + "** browser " +
          ((!response.hasOwnProperty("browser_version") || response.browser_version === "") ? "" :
          "(v" + response.browser_version + ")") + ".")
      return ( device_statement + browser_statement )
    })
    .catch((error) => {
      console.log(error)
      return ("I apologize, it appears we encountered difficulties in locating the answer. Please attempt your inquiry again or consider altering the question.")
    })
}