import axios from "axios"
import { ReCaptchaInstance, load } from "recaptcha-v3"

import * as config from "../config"

const THE_CAPTCHA_ID = "6Lfg3coUAAAAADg5IW92OwNwuN0INt_8qWaoWxNF" // recaptcha v3 client id

export const CEmptyEchoResponse  = {
    ip: "unknown",
    os: "unknown",
    osVersion: "",
    browserName: "unknown",
    browserVersion: "",    
    bot_status: "unknown",
    bot_score:0.0,
    geolocation: {
        country: "unknown",
        countryCode: "",
        region: "",
        regionName: "",
        city: "",
        lat: 0.0,
        lon: 0.0,
        isp: "unknown"
    },
    is_tor: false
} 

// Init and helpers 
export const InitRechaptchaApi = () => {
    return new Promise((resolve, reject) => {
        load(THE_CAPTCHA_ID)
            .then((recaptcha) => {
                resolve(RecaptchaContext)
            })
            .catch(error => {
                reject(error)
            })
    }) // load the recaptch v3 client
}

export const GetRecaptchaTokenApi = (recaptchaContext) => {

    const recaptcha = recaptchaContext.recaptcha
    return new Promise((resolve, reject) => {
        recaptcha.execute("uwhoInfo") // execute recaptch v3 calc and get back token
            .then((token) => {
                resolve(token)
            })
            .catch(error => {
                reject(error)
            })
    })
}

// API enpoints
export const EchoApi = () => {
    const url = `${config.CBaseUrl}/v1/echo`
    return new Promise( (resolve, reject) => {
        axios.get(url, config.CEchoHeader)
            .then(result => {

                const echoResponse = CEmptyEchoResponse
                echoResponse.ip = result.data["ip"]
                echoResponse.browserName = result.data["browser_name"]
                echoResponse.browserVersion = result.data["browser_version"]
                echoResponse.os = result.data.os
                echoResponse.osVersion = result.data["os_version"]
                echoResponse.geolocation["country"] = result.data["geolocation"]["country"]
                echoResponse.geolocation["countryCode"] = result.data["geolocation"]["countryCode"]
                echoResponse.geolocation["region"] = result.data["geolocation"]["region"]
                echoResponse.geolocation["regionName"] = result.data["geolocation"]["regionName"] 
                echoResponse.geolocation["city"] = result.data["geolocation"]["city"]
                echoResponse.geolocation["lat"] = result.data["geolocation"]["lat"] 
                echoResponse.geolocation["lon"] = result.data["geolocation"]["lon"] 
                echoResponse.geolocation["isp"] = result.data["geolocation"]["isp"]
                echoResponse.is_tor = result.data["is_tor"]
            
                resolve(echoResponse)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}

export const EchoWithRecaptchaApi = (token) => {

    const url = `${config.CBaseUrl}/v1/echo?recaptcha_response=${token}`
    return new Promise( (resolve, reject) => {
        axios.get(url, config.CEchoHeader)
            .then(result => {

                const echoResponse = CEmptyEchoResponse
                echoResponse.ip = result.data["ip"]
                echoResponse.userAgent = result.data["user_agent"]
                echoResponse.browserName = result.data["browser_name"]
                echoResponse.browserVersion = result.data["browser_version"]
                echoResponse.os = result.data.os
                echoResponse.osVersion = result.data["os_version"]
                echoResponse.geolocation["country"] = result.data["geolocation"]["country"]
                echoResponse.geolocation["countryCode"] = result.data["geolocation"]["countryCode"]
                echoResponse.geolocation["region"] = result.data["geolocation"]["region"]
                echoResponse.geolocation["regionName"] = result.data["geolocation"]["regionName"] 
                echoResponse.geolocation["city"] = result.data["geolocation"]["city"]
                echoResponse.geolocation["lat"] = result.data["geolocation"]["lat"] 
                echoResponse.geolocation["lon"] = result.data["geolocation"]["lon"] 
                echoResponse.geolocation["isp"] = result.data["geolocation"]["isp"]
                echoResponse.is_tor = result.data["is_tor"]
                echoResponse.bot_status = result.data["bot_status"]
                echoResponse.bot_score = result.data["bot_score"]

                resolve(echoResponse)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}
