// src/pages/ReflectionPage.js
import React from 'react';
import '../tailwind.css';
import { Reflection } from '../components/Reflection'

export const ReflectionPage = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <div><Reflection/></div>
        </div>
    )
}