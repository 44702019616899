// src/components/Header.js
import React, { useState, useEffect } from "react";
import '../tailwind.css';

// My apis
import { EchoApi, CEmptyEchoResponse } from "../apis/echoApi"

export const Reflection = () => {

  // Setting up the State
  const [echo, setEcho] = useState(CEmptyEchoResponse)
  const [isLoading, setIsLoading] = useState(false)

  const refreshData = () => {
    setEcho(CEmptyEchoResponse)
    setIsLoading(true)

    EchoApi()
      .then((echoResponse) => {
        setEcho(echoResponse)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error Loading data. ", error)
        setIsLoading(false)
      })
  }

  // ////////////////////////////////
  // When component is first loaded: Load the recaptcha javascript
  // ////////////////////////////////
  useEffect(() => {

    refreshData()
  },
    [] // this is important to make sure the useEffect is called only once for the first render
  )

  // ////////////////////////////////
  // Render the data
  // ////////////////////////////////
  const renderLocation = (isLoading) ? <span>Loading...</span> :
    <span>
      <span className="text-purple-600">{echo.geolocation.city}</span>
      {(echo.geolocation.region !== "") ? ", " : ""}
      <span className="text-purple-600">{echo.geolocation.region}</span>
      {(echo.geolocation.country !== "") ? ", " : ""}
      <span className="text-purple-600">{echo.geolocation.country}</span>
      {(echo.geolocation.countryCode !== "") ? " (" + echo.geolocation.countryCode + ")" : ""}
    </span>

  const renderTor = (isLoading) ? <span>Loading...</span> :
    <span>
      <span className="text-purple-600">{echo.is_tor ? "on" : "not on"}</span> the Tor network
    </span>

  const renderDevice = (isLoading) ? <span>Loading...</span> :
    <span>
      {(echo.os === 'unknown') ? "Device information is not available" : "Your are running a "}
      <span className="text-purple-400">{(echo.os === 'unknown') ? "" : echo.os + " (v" + echo.osVersion + ")"}</span>
      {(echo.browserName === 'unknown') ? "" : "\nusing a " + echo.browserName + " (v" + echo.browserVersion + ")"}
    </span>

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <div className="pb-16" style={{ fontFamily: '"Lato", sans-serif' }}>
        <dh-component>
          <section className="max-w-8xl mx-auto container pt-16">
            <div>
              <div role="contentinfo" className="flex items-center flex-col px-4">
                <p tabIndex={0} className="focus:outline-none uppercase text-sm text-center text-gray-600 leading-4">Reflection by uwho.info</p>
                <h1 tabIndex={0} className="focus:outline-none text-4xl lg:text-4xl font-extrabold text-center leading-10 text-gray-800 lg:w-5/12 md:w-9/12 pt-4">See what they see...</h1>
              </div>
              <div tabIndex={0} aria-label="group of cards" className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4">
                <div tabIndex={0} aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                  <div className="w-20 h-20 relative mr-5">
                    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                    <div className="absolute text-white bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-10/12">
                    <h2 tabIndex={0} className="focus:outline-none text-lg font-bold leading-tight text-gray-800">Your IP Address</h2>
                    <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">Your IP Address is
                      &nbsp;<span className="text-purple-400">{echo.ip}</span>&nbsp;
                      <button variant="light" size="sm" onClick={() => { navigator.clipboard.writeText(echo.ip) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                          <path d="M7 3.5A1.5 1.5 0 0 1 8.5 2h3.879a1.5 1.5 0 0 1 1.06.44l3.122 3.12A1.5 1.5 0 0 1 17 6.622V12.5a1.5 1.5 0 0 1-1.5 1.5h-1v-3.379a3 3 0 0 0-.879-2.121L10.5 5.379A3 3 0 0 0 8.379 4.5H7v-1Z" />
                          <path d="M4.5 6A1.5 1.5 0 0 0 3 7.5v9A1.5 1.5 0 0 0 4.5 18h7a1.5 1.5 0 0 0 1.5-1.5v-5.879a1.5 1.5 0 0 0-.44-1.06L9.44 6.439A1.5 1.5 0 0 0 8.378 6H4.5Z" />
                        </svg>
                      </button>
                      <br />
                      and you are {renderTor}.
                    </p>
                  </div>
                </div>
                <div tabIndex={0} aria-label="card 2" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                  <div className="w-20 h-20 relative mr-5">
                    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                    <div className="absolute text-white bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-10/12">
                    <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-gray-800">Your Location</h2>
                    <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">
                      You are located at {renderLocation}.
                      <br />
                      The internet provider is {echo.geolocation.isp}.
                    </p>
                  </div>
                </div>
                <div tabIndex={0} aria-label="card 3" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                  <div className="w-20 h-20 relative mr-5">
                    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                    <div className="absolute text-white bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-10/12">
                    <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-gray-800">Your Device</h2>
                    <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">
                      {renderDevice}.
                    </p>
                  </div>
                </div>
                <div tabIndex={0} aria-label="card 4" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                  <div className="w-20 h-20 relative mr-5">
                    <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1" />
                    <div className="absolute text-white bottom-0 left-0 bg-indigo-700 rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                      </svg>
                    </div>
                  </div>
                  <div className="w-10/12">
                    <h2 tabIndex={0} className="focus:outline-none text-lg font-semibold leading-tight text-gray-800">Are you a Human?</h2>
                    <p tabIndex={0} className="focus:outline-none text-base text-gray-600 leading-normal pt-2">
                      To be tested soon...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </dh-component>
      </div >
    </div >
  )
}
