// src/pages/LandingPage.js
import React from 'react';
import '../tailwind.css';

export const AboutPage = () => {
    return (
        <div className="relative isolate 2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-12xl py-32 sm:py-48 lg:py-56">
                <div className="text-center">
                    <div className="flex flex-col lg:flex-row justify-between gap-8">
                        <div className="w-full lg:w-5/12 flex flex-col justify-center">
                            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">About Us</h1>
                            <p className="font-normal text-xl lg:text-xl leading-6 text-gray-600 ">
                                We are <span className="text-gray-700">uwho.info</span> (pronounced as <i><em>you·​who·​info</em></i>).
                                <br />
                                We built a set of web-based utilities we found useful for our work. Hope you find it useful too.
                            </p>
                            <br />
                            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">Thanks</h1>
                            <p className="font-normal text-xl lg:text-xl leading-6 text-gray-600 ">
                                We utilize various data sources including&nbsp;-
                                <br/>
                                <a className="underline" href="https://dev.maxmind.com/geoip/geoip2/downloadable/#MaxMind_APIs">MaxMind</a>&nbsp;,&nbsp;
                                <a className="underline" href="https://www.torproject.org/">torproject.org</a>&nbsp;,&nbsp;
                                <a className="underline" href="https://db-ip.com/db/download/ip-to-country-lite">db-ip.com</a>&nbsp;,&nbsp;
                                <a className="underline" href="https://ip-api.com">ip-api.com</a>&nbsp;,&nbsp;
                                and <a className="underline" href="https://developers.google.com/recaptcha/docs/v3">Google Recaptcha v3</a>.
                            </p>
                        </div>
                        <div className="w-full lg:w-8/12 ">
                            <img className="w-full h-full" src="/uwho-about.png" alt="youwho about" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}