import React, { useState, useEffect, useRef } from 'react';
import { FiCopy } from 'react-icons/fi'; // For the copy icon
import { FaRobot, FaUser } from 'react-icons/fa'; // For app and user icons

// list of available actions and their implementation
import { getExternalIP } from '../actions/GetExternalIP';
import { getOsBrowser } from '../actions/GetOsBrowser';
import { getLocation } from '../actions/GetLocation';
import { getUserAgent } from '../actions/GetUserAgent';

import { RenderMarkdown } from '../components/RenderMarkdown';

const nopAction = () => {
    return "I'm currently in the learning process. Hopefully, I'll have an answer for that soon.";
};

const questions = [
    { text: "What is my external IP address?", action: getExternalIP },
    { text: "What device and browser am I using?", action: getOsBrowser },
    { text: "Where am I located?", action: getLocation },
    { text: "Can you show the raw User-Agent header?", action: getUserAgent },
    //    { text: "Am I using Tor?", action: nopAction },
];

export const ChatPage = () => {
    const chatLogRef = useRef(null);
    const [messages, setMessages] = useState([
        { id: 'welcome', text: "Hello there! How may I assist you today?", sender: 'bot' },
    ]);

    useEffect(() => {
        // Scroll to the bottom of the div by setting scrollTop to scrollHeight
        if (chatLogRef.current) {
            chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
        }
    });

    const handleQuestionClick = async (question) => {

        const answer = await question.action();
        setMessages([...messages, { id: Date.now(), text: question.text, sender: 'user' }, { id: Date.now() + 1, text: answer, sender: 'bot' }]);
    };

    return (
        <div className="flex flex-col justify-between h-screen mt-16">
            <div className="flex flex-col flex-grow overflow-y-auto p-4 space-y-4 items-center">
                <div ref={chatLogRef} className="overflow-y-auto w-2/3" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                    {messages.map((message) => (
                        <div key={message.id} className="mb-4 grid grid-cols-[20px,auto] gap-0">
                            <div className="flex items-center h-[32px] overflow-hidden w-[20px] text-right p-0 m-0">
                                {message.sender === 'bot' ?
                                    <FaRobot className="mr-2 text-indigo-500" /> :
                                    <FaUser className="mr-2 text-indigo-500" />}
                            </div>
                            <div className="p-0 m-0">
                                <div className="flex items-center h-[32px] overflow-hidden font-bold ">
                                    {message.sender === 'bot' ? 'uwho:' : 'you:'}
                                </div>
                                {message.sender !== 'bot' ?
                                    <div className="p-2">
                                        {message.text}
                                    </div>
                                    :
                                    <div className="bg-gray-100 rounded-md p-2">
                                        <RenderMarkdown markdown={message.text} />
                                        {message.id !== 'welcome' && (
                                            <button onClick={() => navigator.clipboard.writeText(message.text)}>
                                                <FiCopy />
                                            </button>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-2/3 mx-auto">
                <div className="grid grid-cols-4 gap-4 p-4 bg-white shadow-md fixed inset-x-0 bottom-4" style={{ paddingBottom: '1rem' }}>
                    {questions.map((question, index) => (
                        <button key={index} className="border border-indigo-500 bg-white text-indigo-500 font-bold p-2 rounded-md" onClick={() => handleQuestionClick(question)}>
                            {question.text}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};