
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// Pages and Components
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { LandingPage } from './pages/LandingPage'
import { ReflectionPage } from './pages/ReflectionPage'
import { ChatPage } from './pages/ChatPage'
import { AboutPage } from './pages/AboutPage'

// Styles
import './tailwind.css';

export default function App() {

  return (
    <div className="bg-white">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/reflection" element={<ReflectionPage/>} />
          <Route path="/chat" element={<ChatPage/>} />
          <Route path="/about" element={<AboutPage/>} />
         <Route path="*" element={<Navigate replace to="/" />} /> {/* Catch-all route: Redirects to the main page */}
        </Routes>
      </Router>
      <Footer />
    </div>
  )
}
