// src/pages/LandingPage.js
import React from 'react';
import '../tailwind.css';
import { Hero } from '../components/Hero'

export const LandingPage = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow"><Hero/></div>
        </div>
    )
}