// src/components/Footer.js
import React from 'react';
import '../tailwind.css';

export const Footer = () => {
  return (
    <footer className="fixed bottom-1 w-full bg-white">
      <div className="flex items-center justify-between p-0.01 top-0 h-px bg-gray-200"></div>
      <div className="text-center text-sm text-gray-500">
        © 2024 uwho.info | All rights reserved.
      </div>
    </footer>
  );
}
