import axios from "axios"

import * as config from "../config"

// API enpoints
export const getFullEchoApi = async ()  => {
    const url = `${config.CBaseUrl}/v1/echo`

    return new Promise( (resolve, reject) => {
        axios.get(url)
            .then(result => {
                resolve(result.data)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}
