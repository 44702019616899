// Implement GetUserAgent action

import { getFullEchoApi } from "../apis/GetFullEchoApi"

export const getUserAgent = async () => {

  return await getFullEchoApi()
    .then((response) => {
      const user_agent_statement = !response.hasOwnProperty("user_agent") ? "I was not able to analyzie the User-agent header." :
        ("The raw User_Agent HTTP header is -" +
          "\n\n**" + response.user_agent) + "**"
      return ( user_agent_statement )
    })
    .catch((error) => {
      console.log(error)
      return ("I apologize, it appears we encountered difficulties in locating the answer. Please attempt your inquiry again or consider altering the question.")
    })
}