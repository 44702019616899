// src/components/RenderMarkdown.js
import React from 'react';
import PropTypes from 'prop-types';

import SimpleMarkdown from 'simple-markdown';

// Rendering everything
export const RenderMarkdown = ({ markdown }) => {
    const mdParse = SimpleMarkdown.defaultBlockParse;
    const mdOutput = SimpleMarkdown.defaultReactOutput;
    const parsedMarkdown = mdParse(markdown);

    return mdOutput(parsedMarkdown)
};

RenderMarkdown.propTypes = {
    markdown: PropTypes.string.isRequired
};