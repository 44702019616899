// Implement GetUserAgent action

import { getFullEchoApi } from "../apis/GetFullEchoApi"

export const getLocation = async () => {

    return await getFullEchoApi()
        .then((response) => {
            if (!response.hasOwnProperty("geolocation"))
                return "Location information was not found."

            const city = (!response.geolocation.hasOwnProperty("city") ? "" : response.geolocation.city)
            const region_name = (!response.geolocation.hasOwnProperty("regionName") ? "" : response.geolocation.regionName)
            const country = (!response.geolocation.hasOwnProperty("country") ? "" : response.geolocation.country)
            const country_code = (!response.geolocation.hasOwnProperty("countryCode") ? "" : response.geolocation.countryCode)
            const isp = (!response.geolocation.hasOwnProperty("isp") ? "" : response.geolocation.isp)

            const location_statement = "Your IP address is from the area of **" +
                (city === "" ? "" : city + ", ") +
                (region_name === "" ? "" : region_name + ", ") +
                country + ' (' + country_code + ")**. "
            const isp_statement = (isp === "" ? "" :
                "I also found that your internet provider is **" + isp + "**. ")

            return ("Location information is always approximation, so this is what I found - \n\n" +
                location_statement + '\n\n' + isp_statement)
        })
        .catch((error) => {
            console.log(error)
            return ("I apologize, it appears we encountered difficulties in locating the answer. Please attempt your inquiry again or consider altering the question.")
        })
}