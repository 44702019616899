// Implement GetExternalIP action

import { getExternalIPApi } from "../apis/GetExternalIPApi"

export const getExternalIP = async () => {

  return await getExternalIPApi()
    .then((response) => {
      return ("Your external ip, as seen by an external server, is -\n\n**" + response + "**")
    })
    .catch((error) => {
      console.log(error)
      return ("I apologize, it appears we encountered difficulties in locating the answer. Please attempt your inquiry again or consider altering the question.")
    })
}